.CatalogModelCard {
  max-width: 312px;
  font-family: $font;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__Viewer {
    max-width: 312px;
    height: 234px;
    background: #ffffff;
    box-shadow: $dropshadow-grey-light;
    border-radius: 16px;
    position: relative;

    &__Icon {
      position: absolute;
      top: 12px;
      right: 12px;
      z-index: 1;
    }
  }

  &__Info {
    margin-top: 16px;
    margin-bottom: 8px;

    &__Title {
      color: $text-primary;
      @include sm-14-bold;
    }

    &__Text {
      color: $text-secondary;
      white-space: pre-line;
      word-break: break-all;
      @include sm-12-medium;
    }
  }

  &__Button {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    color: $text-primary;
    border: 1px solid $states-mediumgrey-normal;
    width: 100%;
    border-radius: 10px;
    padding: 9px 0;
    @include md-16-bold;
    position: relative;

    &__Hidden {
      width: 0;
      height: 0;
      overflow: hidden;
    }
  }
}
