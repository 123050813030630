.ErrorPage {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 48px;

  @media (min-width: 800px) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  &__info {
    z-index: 100;
    margin: 0;
    padding: 12px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 24px;

    @media (min-width: 800px) {
      margin-left: 12%;
      margin-bottom: 24vh;
    }

    &__title {
      color: $bi-primary1;
      @include lg-24-extrabold;
      margin-bottom: 24px;

      @media (min-width: 800px) {
        @include lg-40-extrabold;
        margin-bottom: 24px;
      }
    }

    &__content {
      color: $bi-primary3;
      white-space: pre-line;
      @include md-16-bold;

      @media (min-width: 800px) {
        @include md-21-bold;
      }
    }
  }

  &__img {
    position: absolute;
    z-index: 0;
    right: 0px;
    bottom: 0px;
    max-width: 80%;

    @media (min-width: 800px) {
      max-width: 40%;
    }
  }
}
