.CatalogModelModal {
  font-family: $font;
  padding: 36px 0;
  overflow-y: auto;

  @media (min-width: 960px) {
    padding: 20px 60px;
    overflow-y: visible;
    pointer-events: none;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  &__backdrop {
    position: fixed;
    top: -10px;
    left: -10px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: calc(100% + 20px);
    height: calc(100vh + 20px);
    padding: 0 34px;
    background-color: $dimmed-dark1;
  }

  &__Header {
    display: flex;
    justify-content: space-between;

    @media (min-width: 960px) {
      display: none;
    }
  }

  &__Contents {
    display: block;

    @media (min-width: 960px) {
      display: flex;
      gap: 5%;
    }

    &__Controls {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media (min-width: 960px) {
        width: 30%;
      }

      &__Title {
        display: none;
        color: $text-secondary;
        @include md-18-extrabold;

        @media (min-width: 960px) {
          display: block;
        }
      }

      &__Button {
        display: none;
        margin-top: auto;

        @media (min-width: 960px) {
          display: block;
        }
      }
    }
  }

  &__Title {
    color: #ffffff;
    @include md-16-bold;

    &__3D {
      color: $bi-primary1;
    }
  }

  &__Paper {
    margin-top: 16px;
    border-radius: 8px;
    width: 100%;
    height: 50%;
    min-height: 283px;
    position: relative;

    @media (min-width: 960px) {
      margin-top: 0;
    }

    &__Viewer {
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: auto;
    }
  }
}

.GuideModal {
  display: none;

  @media (min-width: 960px) {
    background-color: $dimmed-dark2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 26px;
    z-index: 1000;

    &__CloseButton {
      position: absolute;
      top: 32px;
      right: 32px;
      z-index: 1100;
    }
  }
}
