.Catalog {
  font-family: $font;
  padding: 55px 24px;

  @media (min-width: 500px) and (max-width: 1023px) {
    padding: 55px 40px;
  }

  @media (min-width: 1024px) {
    padding: 55px 64px;
  }

  &__Container {
    max-width: 312px;
    margin: 0 auto;

    @media (min-width: 500px) and (max-width: 1023px) {
      max-width: 648px;
    }

    @media (min-width: 1024px) {
      max-width: 1158px;
    }

    &__Header {
      margin-bottom: 30px;

      &__Title {
        font-style: normal;
        @include md-21-extrabold;
      }

      &__Sub {
        font-style: normal;
        @include sm-14-bold;
      }
    }

    &__Contents {
      display: flex;
      flex-direction: column;
      gap: 40px;

      @media (min-width: 500px) and (max-width: 1023px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 36px;
        column-gap: 24px;
      }

      @media (min-width: 1024px) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 56px;
        @supports (column-gap: clamp(48px, 5%, 87px)) {
          column-gap: clamp(48px, 5%, 87px);
        }
        @supports not (column-gap: clamp(48px, 5%, 87px)) {
          column-gap: 87px;
        }
      }
    }

    &__Pagination {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }

    &__Hr {
      display: none;
    }

    &__Footer {
      margin-top: 40px;
      color: $bi-primary1;
      text-align: center;
      @include sm-14-bold;
    }
  }
}

// @media (min-width: 481px) {
//   .Catalog {
//     padding: 50px 0;

//     &__Container {
//       max-width: 1024px;

//       &__Header {
//         display: flex;
//         align-items: baseline;
//         margin-bottom: 56px;
//         gap: 40px;

//         &__Title {
//           @include lg-32-extrabold;
//         }

//         &__Sub {
//           @include md-18-bold;
//         }
//       }

//       &__Contents {
//         display: grid;
//         grid-template-columns: repeat(3, 1fr);
//         row-gap: 56px;
//         column-gap: 87px;
//       }

//       &__Pagination {
//         margin-top: 64px;
//         text-align: center;
//       }

//       &__Hr {
//         margin-top: 72px;
//         margin-bottom: 24px;
//         border-color: $etc-divider1;
//       }

//       &__Footer {
//         text-align: center;
//         font-family: $font;
//         font-style: normal;
//         color: $bi-primary1;
//         @include md-18-bold;
//       }
//     }
//   }
// }
