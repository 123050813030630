$font: 'Pretendard', sans-serif;
// New Colors
// Brand Identity
$bi-primary1: #6073ff;
$bi-primary2: #4758d3;
$bi-primary3: #cbd2ff;
$bi-primary4: #f2f4fc;
$bi-secondary1: #72d7e4;
$bi-secondary2: #60ecff;
$bi-gradation1: linear-gradient(90deg, #6073ff 0%, #60ecff 100%);
$bi-gradation2: linear-gradient(90deg, #d9ddff 0%, #d9faff 100%);

// Greyscale
$grey-950: #0c0d0d;
$grey-900: #19191a;
$grey-850: #252626;
$grey-800: #323233;
$grey-750: #3e3f40;
$grey-700: #4b4b4d;
$grey-650: #575859;
$grey-600: #646466;
$grey-550: #707073;
$grey-500: #7d7d80;
$grey-450: #898a8c;
$grey-400: #969699;
$grey-350: #a2a3a6;
$grey-300: #aeafb2;
$grey-250: #bbbbbf;
$grey-200: #c7c8cc;
$grey-150: #d3d4d9;
$grey-100: #e1e2e5;
$grey-50: #eeeff2;
$grey-10: #f6f6fa;
$grey-0: #ffffff;

// States
$states-white-normal: #ffffff;
$states-white-highlighted: #eeeff2;
$states-white-disabled: rgba(255, 255, 255, 0.4);

$states-lightgrey-normal: #eeeff2;
$states-lightgrey-highlighted: #d3d4d9;
$states-lightgrey-disabled: rgba(199, 200, 204, 0.4);

$states-mediumgrey-normal: #c7c8cc;
$states-mediumgrey-highlighted: #4b4b4d;
$states-mediumgrey-disabled: rgba(199, 200, 204, 0.4);

$states-darkgrey-normal: #646466;
$states-darkgrey-highlighted: #0c0d0d;
$states-darkgrey-disabled: rgba(37, 38, 38, 0.4);

$states-primary-normal: #6073ff;
$states-primary-highlighted: #4758d3;
$states-primary-disabled: rgba(150, 150, 153, 0.4);

$states-secondary-normal: #72d7e4;
$states-secondary-highlighted: #70e6f6;
$states-secondary-disabled: rgba(150, 150, 153, 0.4);

// Text
$text-primary: #0c0d0d;
$text-secondary: #646466;
$text-disabled: #bbbbbf;
$text-placeholder: #bbbbbf;
$text-white: #ffffff;

// Background
$bg-white: #ffffff;
$bg-lightgrey: #f6f6fa;

// Dimmed Effect
$dimmed-dark1: rgba(12, 13, 13, 0.85);
$dimmed-dark2: rgba(12, 13, 13, 0.7);
$dimmed-dark3: rgba(12, 13, 13, 0.35);

// Other
$etc-divider1: rgba(12, 13, 13, 0.08);
$etc-divider2: rgba(12, 13, 13, 0.3);
$etc-border: #e1e2e5;
$etc-notification-bg: #252626;

// Effect
$dropshadow-grey-light: 0px 4px 16px rgba(0, 0, 0, 0.08);
$dropshadow-grey-medium: 0px 8px 24px rgba(0, 0, 0, 0.12);
$dropshadow-grey-semidark: 0px 8px 24px rgba(0, 0, 0, 0.18);
$dropshadow-primary-light: 0px 4px 12px rgba(96, 115, 255, 0.1);
$dropshadow-primary-medium: 0px 8px 16px rgba(96, 115, 255, 0.16);
$dropshadow-primary-semidark: 0px 8px 24px rgba(96, 115, 255, 0.3);
$dropshadow-secondary-light: 0px 3px 10px rgba(112, 230, 246, 0.24);
$dropshadow-secondary-medium: 0px 4px 14px rgba(112, 230, 246, 0.32);
$dropshadow-secondary-semidark: 0px 8px 24px rgba(112, 230, 246, 0.5);

// Notification
$notification-success-main: #5ec45f;
$notification-success-dark: #3b8034;
$notification-success-light: #eff9ef;
$notification-warning-main: #e94a51;
$notification-warning-dark: #c53434;
$notification-warning-light: #fdedee;
$notification-caution-main: #f8954d;
$notification-caution-dark: #d7742c;
$notification-caution-light: #fbf1ea;
$notification-information-main: #56a1f9;
$notification-information-dark: #3584e0;
$notification-information-light: #ebf4ff;

// Rainbow
$rainbow-red: #ffebf5;
$rainbow-orange: #fff3eb;
$rainbow-yellow: #f6fbdb;
$rainbow-green: #ebfff0;
$rainbow-blue: #ebf7ff;
$rainbow-navy: #ecebff;
$rainbow-purple: #f9ebff;

@mixin for-phone-only {
  @media (max-width: 767px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 1024px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1280px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1920px) {
    @content;
  }
}

@mixin sm-12-medium {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 22px;
}

@mixin sm-12-semibold {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 22px;
}

@mixin sm-12-extrabold {
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0.5px;
  line-height: 22px;
}

@mixin sm-14-medium {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 26px;
}

@mixin sm-14-semibold {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 26px;
}

@mixin sm-14-bold {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.1px;
  line-height: 26px;
}

@mixin sm-14-extrabold {
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 0.25px;
  line-height: 26px;
}

@mixin md-16-medium {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 28px;
}

@mixin md-16-semibold {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 28px;
}

@mixin md-16-bold {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 30px;
}

@mixin md-16-extrabold {
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0.25px;
  line-height: 30px;
}

@mixin md-18-medium {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.1px;
  line-height: 32px;
}

@mixin md-18-bold {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.1px;
  line-height: 32px;
}

@mixin md-18-extrabold {
  font-size: 18px;
  font-weight: 800;
  letter-spacing: 0.25px;
  line-height: 32px;
}

@mixin md-21-semibold {
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 34px;
}

@mixin md-21-bold {
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 34px;
}

@mixin md-21-extrabold {
  font-size: 21px;
  font-weight: 800;
  letter-spacing: 0.25px;
  line-height: 36px;
}

@mixin lg-24-bold {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.1px;
  line-height: 38px;
}

@mixin lg-24-extrabold {
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0.25px;
  line-height: 38px;
}

@mixin lg-28-bold {
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.1px;
  line-height: 46px;
}

@mixin lg-28-extrabold {
  font-size: 28px;
  font-weight: 800;
  letter-spacing: 0.25px;
  line-height: 46px;
}

@mixin lg-32-semibold {
  font-size: 32px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 50px;
}

@mixin lg-32-extrabold {
  font-size: 32px;
  font-weight: 800;
  letter-spacing: 0.25px;
  line-height: 50px;
}

@mixin lg-40-extrabold {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 62px;
}

@mixin lg-70-extrabold {
  font-size: 70px;
  font-weight: 800;
  letter-spacing: 2px;
  line-height: 110px;
}
