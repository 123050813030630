body {
  margin: 0;
  display: flex;
  font-weight: 400;
  font-family: 'Pretendard', sans-serif;
  background-color: #ffffff;
  height: 100vh;
}

#root {
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media screen and (min-width: 360px) {
  .container,
  .container-fluid {
    padding: 0 24px;
  }
}

@media screen and (min-width: 1920px) {
  .container,
  .container-fluid {
    padding: 0 32px;
  }
}
